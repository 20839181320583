<template>
  <form class="signup-form" @submit.prevent="signup">
    <!-- Header -->
    <div class="headline-medium">
      {{ $t('navigation.signup') }}
    </div>

    <div>
      <!-- Inputs -->
      <div class="w-full mt-s24">
        <CustomInput
          ref="emailRef"
          v-model="email"
          data-testid="input-email"
          data-cy="input-email"
          :placeholder="$t('email.address')"
          type="email"
        />

        <CustomInput
          class="mt-s16"
          v-model="password"
          data-testid="input-password"
          data-cy="input-password"
          :placeholder="$t('password.label')"
          :type="passwordVisible ? 'text' : 'password'"
        >
          <template v-slot:icon>
            <div class="body-text-x-medium cursor-pointer" @click="togglePasswordVisible">
              {{ $t(`password.${passwordVisible ? 'hide' : 'show'}`) }}
            </div>
          </template>
        </CustomInput>

        <CustomInput
          class="default-transition overflow-hidden"
          :class="{ 'mt-s16': showingRefInput }"
          :style="{ 'max-height': showingRefInput ? '48px' : '0', 'opacity': showingRefInput ? 1 : 0 }"
          v-model="ref"
          :placeholder="$t('referral.placeholder')"
          data-testid="input-referral"
          :lp-ignore="true"
          :disabled="!!referralCode"
          type="text"
        />

        <div
          class="default-transition overflow-hidden"
          :class="{ 'mt-s24': password }"
          :style="{ 'max-height': password ? '100px' : '0', 'opacity': password ? 1 : 0 }"
        >
          <div class="subheadline-small">
            {{ $t('password.requirements_label') }}
          </div>
          <div class="mt-s8">
            <span class="body-text-large">{{ $t('password.requirements_description') }}</span>
            <span class="body-text-x-large">{{ $t('password.requirements_special_chars') }}</span>
          </div>
        </div>

        <div
          v-if="!showingRefInput"
          @click="showRefInput"
          class="mt-s16 text-text-positive body-text-x-large link-text hover:opacity-70"
        >
          {{ $t('referral.question') }}
        </div>
      </div>

      <!-- User agreement -->
      <div class="flex justify-between items-center mt-s24">
        <div>
          <Checkbox v-model="userAgrees" testId="user-agrees">
            <div class="body-text-large">
              <span>
                {{$t('security.i_certify')}}
              </span>
              <router-link to="/user-agreement" target="_blank" rel="noopener noreferrer">
                <span class="body-text-x-large text-text-positive default-transition hover:opacity-70">
                  {{$t('security.terms_and_conditions')}}
                </span>
              </router-link>
            </div>
          </Checkbox>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="mt-s32">
      <ButtonV2
        :label="$t('navigation.signup')"
        :inactive="isLoading || !isFormValid"
        data-testid="button-signup"
        data-cy="button-signup"
        size="medium"
        submit
        wide
      />
      <ButtonV2
        @onClick="goToInternalPageGlobal('/login')"
        data-testid="button-login"
        data-cy="button-login"
        :label="$t('navigation.login')"
        class="mt-s16"
        version="secondary"
        size="medium"
        wide
      />
    </div>

    <!-- SSO -->
    <LineSeparator v-if="showSsoApple || showSsoGoogle" :label="$t('or')"/>
    <ButtonV2
      v-if="showSsoApple"
      data-testid="button-signup-apple"
      data-cy="button-signup-apple"
      @onClick="$emit('onAppleAuth')"
      :label="$t('sso.signup_apple')"
      version="secondary"
      size="medium"
      icon="apple"
      icon-style="fab"
      wide
    />
    <ButtonV2
      v-if="showSsoGoogle"
      data-testid="button-signup-google"
      data-cy="button-signup-google"
      @onClick="$emit('onGoogleAuth')"
      :label="$t('sso.signup_google')"
      class="mt-s16"
      version="secondary"
      size="medium"
      icon="google"
      icon-style="fab"
      wide
    />
  </form>
</template>

<script>
import { ButtonV2, CustomInput, LineSeparator } from '@/components/misc';
import Checkbox from '@/components/misc/Checkbox';
import { isPasswordValid } from '@/utils/validate-password';

export default {
  name: 'SignupForm',
  components: {
    ButtonV2,
    CustomInput,
    Checkbox,
    LineSeparator,
  },

  computed: {
    isFormValid() {
      return this.userAgrees && this.email && this.isPasswordValid;
    },

    isPasswordValid() {
      return isPasswordValid(this.password);
    },
  },

  data: function () {
    return {
      email: '',
      password: '',
      passwordVisible: false,
      ref: '',
      showingRefInput: false,
      userAgrees: false,
    };
  },

  methods: {
    showRefInput() {
      this.showingRefInput = true;
    },

    signup() {
      this.$emit('onSignup', {
        email: this.email,
        password: this.password,
        ref: this.ref,
        userAgrees: this.userAgrees,
        type: 'form'
      });
    },

    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
  },

  mounted() {
    if (this.referralCode) {
      this.ref = this.referralCode;
      this.showingRefInput = true;
    }
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    referralCode: {
      type: String,
      default: null,
    },

    showSsoApple: {
      type: Boolean,
      default: false,
    },

    showSsoGoogle: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped>
  .signup-form {
    @apply my-s48 px-s20;
    width: 100%;
    max-width: 375px;
  }
</style>
